.note {
    padding: 15px;
    margin-bottom: 20px;
    border: 4px solid transparent;
    border-radius: 4px;
    border-left-width: 4px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    background-color: rgba(245,247,249,1.00);
}

.note-warning {
    border-color: #dbcd00;
}

.note-success {
    border-color: #468847;
}

.note-info {
    border-color: rgb(52, 109, 219);
}

.note-danger {
    border-color: rgb(211, 61, 61);
}